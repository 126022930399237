<template>
  <component
    :is="tag"
    v-on="$listeners"
    v-bind="btnAttrs"
    @click="handleClick"
    :class="[
      defaultClass ? btnClass : '',
      { disabled: isDisabledAnchor },
      sizeClass,
      { active }
    ]"
  >
    <slot>Button</slot>
  </component>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    // style type (bootstrap class)
    type: {
      required: false,
      type: String,
      default: "default"
    },
    // whether or not is a submit button.
    // optional form target to submit
    submit: {
      type: [String, HTMLFormElement],
      default: undefined
    },
    // whether or not the default
    // button class should be used
    defaultClass: {
      type: Boolean,
      required: false,
      default: true
    },
    tag: {
      type: String,
      required: false,
      default: "button"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    xSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    btnClass() {
      return ![
        "default",
        "primary",
        "success",
        "info",
        "warning",
        "danger",
        "link"
      ].includes(this.type)
        ? this.type
        : `btn btn-${this.type}`;
    },
    sizeClass() {
      let size =
        [
          [this.xSmall, "xs"],
          [this.small, "sm"],
          [this.large, "lg"]
        ].find((s) => s[0] == true)?.[1] ?? "";
      return size ? `btn-${size}` : "";
    },
    btnType() {
      return this.submit != undefined ? "submit" : "button";
    },
    btnAttrs() {
      let attrs = {};
      switch (this.tag.toLowerCase()) {
        case "button":
          attrs.type = this.btnType;
          attrs.disabled = this.disabled;
          break;
        case "a":
          attrs.role = "button";
      }
      return attrs;
    },
    isDisabledAnchor() {
      return this.disabled && this.tag.toLowerCase() == "a";
    }
  },
  methods: {
    handleClick() {
      if (
        (typeof this.submit == "string" && this.submit.trim()) ||
        this.submit instanceof HTMLFormElement
      ) {
        let form =
          typeof this.submit == "string"
            ? document.querySelector(this.submit)
            : this.submit;
        form.submit();
      }
    }
  }
};
</script>

<style></style>
